import PlayDesigner from "./pages/PlayDesigner";
import Account from "./pages/Account";
import Home from "./pages/Home";
import Organizations from "./pages/Organizations";
import CreateOrganization from "./pages/CreateOrganization";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import AppLayout from "./layouts/AppLayout";
import Organization from "./pages/Organization";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from 'react';
import React from 'react';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import { UserContext } from './UserContext';
import { get } from "aws-amplify/api";

const App = () => {
  const [user, setUser] = useState({
    isAuthorizing: true,
    isAuthorized: false,
    cognito: {},
    attributes: {},
    isFetchingAttributes: false
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const cognito = await getCurrentUser();
        setUser(prevUser => ({ ...prevUser, isAuthorizing: false, isAuthorized: true, cognito, isFetchingAttributes: true }));
        let attempts = 0;
        const maxAttempts = 10;
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        let restOperation;
        while (attempts < maxAttempts) {
          try {
            restOperation = await get({
              apiName: "user_management",
              path: "/users/get",
              options: {
                queryParams: { userId: cognito.userId },
                headers: {
                  "Authorization": (await fetchAuthSession()).tokens?.idToken?.toString()
                }
              }
            });
            break; // If the request is successful, exit the loop
          } catch (error) {
            attempts++;
            if (attempts >= maxAttempts) {
              throw error; // If max attempts reached, throw the error
            }
            await delay(1000 * Math.pow(2, attempts - 1)); // Exponential backoff
          }
        }
        const { body } = await restOperation.response;
        const json = await body.json();
        setUser(prevUser => ({ ...prevUser, attributes: json, isFetchingAttributes: false }));
      } catch {
        setUser(prevUser => ({ ...prevUser, isAuthorizing: false, isFetchingAttributes: false }));
      }
    };

    if (user.isAuthorizing) {
      fetchUserData();
    }
  }, [user.isAuthorizing]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Router>
        <Routes>
          <Route path="/" element={<AppLayout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/account" element={<Account />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/sign-in" element={<SignIn />} />
            <Route exact path="/sign-up" element={<SignUp />} />
            <Route exact path="/play-designer" element={<PlayDesigner />} />
            <Route exact path="/organization/:organizationId" element={<Organization />} />
            <Route exact path="/organizations" element={<Organizations />} />
            <Route exact path="/create-organization" element={<CreateOrganization />} />
          </Route>
        </Routes>
      </Router>
    </UserContext.Provider>
  );
};

export default App;