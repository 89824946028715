import React, { useState } from "react";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import { CircularProgress, Typography } from "@mui/material";



export default function OrganizationGroups({ organizationId }) {
    const [groups, setGroups] = useState([]);
    const [formState, setFormState] = useState({
        isFetching: false,
        isFetched: false,
    });

    const fetchGroups = async () => {
        setFormState({ ...formState, isFetching: true });

        try {
            const restOperation = get({
                apiName: "user_management",
                path: "/groups/list",
                options: {
                    queryParams: { tenantId: organizationId },
                    headers: {
                        "Authorization": (await fetchAuthSession()).tokens?.idToken?.toString()
                    }
                }
            });
            const { body } = await restOperation.response;
            const json = await body.json();
            setGroups(json);
        } catch (error) {
            console.error(error);
        };

        setFormState({ ...formState, isFetching: false, isFetched: true });
    };
    if (!formState.isFetching && !formState.isFetched) {
        fetchGroups();
    }
    return (
        <>
            <Typography variant="h3">Groups</Typography>
            {formState.isFetching && <CircularProgress />}
            {formState.isFetched && groups.length > 0 && groups.map((group, index) => (
                <p key={index}>{group.name}</p>
            ))}
        </>
    );
}
