import { Rect } from "react-konva";
import { Text } from "react-konva";
import { useTheme } from "@mui/material/styles";

const Numbers = ({ fieldDimensions }) => {
    const xRange = [
        fieldDimensions.width * (12 / 65),
        fieldDimensions.width * (53 / 65),
    ];
    const yRange = [30, 40, 50, 60, 70, 75, 80, 90, 100, 110, 120, 130];
    const yLabels = ["10", "20", "30", "40", "50", "C", "50", "40", "30", "20", "10"];
    return (
        <>
            {yRange.map((y) => {
                const label = yLabels[yRange.indexOf(y)];
                return xRange.map((x) => (
                    <Text
                        key={`${x}-${y}`}
                        text={label}
                        x={x}
                        y={fieldDimensions.height * ((y - 1) / 150)}
                        width={fieldDimensions.width * (4 / 65)}
                        height={fieldDimensions.height * (2 / 150)}
                        fontSize={30 * (fieldDimensions.width / 700)} // Adjust font size based on field width
                        fill="white"
                    />
                ));
            })}
        </>
    );
};

const Grass = ({ fieldDimensions }) => {
    const theme = useTheme();
    return (
        <Rect
            width={fieldDimensions.width}
            height={fieldDimensions.height}
            fill={theme.palette.primary.main}
        />
    );
};


const YardLines = ({ fieldDimensions }) => {
    const xRange = [0];
    const yRange = Array.from({ length: 22 }, (_, i) => i * 5 + 20);
    return (
        <>
            {xRange.map((x) =>
                yRange.map((y) => (
                    <Rect
                        key={`${x}-${y}`}
                        x={x}
                        y={fieldDimensions.height * (y / 150)}
                        width={fieldDimensions.width}
                        height={fieldDimensions.height * (0.25 / 150)}
                        fill="white"
                    />
                ))
            )}
        </>
    );
};

const GoalLines = ({ fieldDimensions }) => {
    const endzoneHeight = fieldDimensions.height * (20 / 150);
    return (
        <>
            {[endzoneHeight, fieldDimensions.height - endzoneHeight].map(
                (y, index) => (
                    <Rect
                        key={index}
                        x={0}
                        y={y}
                        width={fieldDimensions.width}
                        height={3}
                        fill="white"
                    />
                )
            )}
        </>
    );
};

const HashMarks = ({ fieldDimensions }) => {
    const xRange = [
        fieldDimensions.width * (0 / 65),
        fieldDimensions.width * (24 / 65),
        fieldDimensions.width * (41 / 65),
        fieldDimensions.width * (64 / 65),
    ];
    const yRange = Array.from({ length: 109 }, (_, i) => i + 21);
    return (
        <>
            {xRange.map((x) =>
                yRange.map((y) => (
                    <Rect
                        key={`${x}-${y}`}
                        x={x}
                        y={fieldDimensions.height * (y / 150)}
                        width={fieldDimensions.width * (1 / 65)}
                        height={fieldDimensions.height * (0.25 / 150)}
                        fill="white"
                    />
                ))
            )}
        </>
    );
};

export default function PermanentFieldMarkings({ fieldDimensions }) {
    return (
        <>
            <Grass fieldDimensions={fieldDimensions} />
            <YardLines fieldDimensions={fieldDimensions} />
            <Numbers fieldDimensions={fieldDimensions} />
            <GoalLines fieldDimensions={fieldDimensions} />
            <HashMarks fieldDimensions={fieldDimensions} />
        </>
    );
}