import { Typography, Box, CardMedia } from "@mui/material";


const AppHome = () => {

    return (
        <>
            <Box>
                <Box display="flex" flexDirection="column" alignItems="center" paddingTop="30px">

                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">

                        <Box display="flex" flexDirection={{ xs: "column", md: "row" }} alignItems="center" justifyContent="center">
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <Typography variant="h2" fontWeight="bold">
                                    clipr
                                </Typography>
                                <Typography variant="h5" >
                                    <a href="/play-designer" style={{ textDecoration: 'none', color: 'blue' }}>
                                        Playbook Designer
                                    </a>
                                </Typography>
                                <CardMedia
                                    component="img"
                                    image={require("../../assets/clipr-icon.png")}
                                    sx={{ width: '80%', }}
                                />
                            </Box>

                        </Box>
                    </Box>
                </Box >
            </Box >
        </>
    );
}

export default AppHome;