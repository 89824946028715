import { Box } from "@mui/material";
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { v4 as uuidv4 } from 'uuid';

const RawAssetsDirectory = [
    'blocking schemes/run game/indy',
    'blocking schemes/pass game/60-61',
    'passing concepts/bench',
    'passing concepts/ipod',
    'formations/war',
    'formations/jaws',
]


function mapRawToFormatted(rawAssets) {
    const formattedAssets = [];

    rawAssets.forEach(path => {
        const parts = path.split('/');
        let currentLevel = formattedAssets;

        parts.forEach((part, index) => {
            let existingItem = currentLevel.find(item => item.label === part);

            if (!existingItem) {
                existingItem = {
                    id: uuidv4(),
                    label: part,
                    children: [],
                };
                currentLevel.push(existingItem);
            }

            if (index === parts.length - 1) {
                delete existingItem.children;
            } else {
                currentLevel = existingItem.children;
            }
        });
    });

    return formattedAssets;
}

const AssetsDirectory = mapRawToFormatted(RawAssetsDirectory);

export default function FileExplorer() {
    return (
        <Box sx={{ minHeight: 352, minWidth: 250 }}>
            <RichTreeView items={AssetsDirectory} />
        </Box>
    );
}