import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";
import OrganizationMembers from "./Members";
import OrganizationGroups from "./Groups";

export default function Organization() {


    const [state, setState] = React.useState({
        organization: {},
        isFetching: false,
        isFetched: false,
    });

    const { organizationId } = useParams();


    const fetchOrganization = async () => {
        setState({ ...state, isFetching: true });
        const restOperation = get({
            apiName: "user_management",
            path: `/tenants/get/${organizationId}`,
            options: {
                headers: {
                    "Authorization": (await fetchAuthSession()).tokens?.idToken?.toString()
                }
            }
        });
        const { body } = await restOperation.response;
        const json = await body.json();
        setState({ ...state, organization: json, isFetching: false, isFetched: true });
    };


    if (!state.isFetching && !state.isFetched) {
        fetchOrganization();
    }



    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="4vh" spacing={2}>
            {state.isFetched && <Typography variant="h1">{state.organization.name}</Typography>}
            {state.isFetching && <CircularProgress />}
            <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center" marginTop="4vh" gap="4vw">
                <OrganizationMembers />
                <OrganizationGroups organizationId={organizationId} />
            </Box>
        </Box>
    );
}