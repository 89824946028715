import React, { useEffect, useState } from "react";
import { Stage, Layer, Circle, Rect } from "react-konva";
import { Box } from "@mui/material";
import PermanentFieldMarkings from "./PermanentFieldMarkings";

const LineOfScrimmage = ({ fieldDimensions, location }) => {
  const y = fieldDimensions.height * (location / 150);
  return (
    <>
      <Rect
        x={0}
        y={y}
        width={fieldDimensions.width}
        height={fieldDimensions.height * (0.25 / 150)}
        fill="blue"
      />
    </>
  );
};

const FirstDownLine = ({ fieldDimensions, location }) => {
  const y = fieldDimensions.height * (location / 150);
  return (
    <>
      <Rect
        x={0}
        y={y}
        width={fieldDimensions.width}
        height={fieldDimensions.height * (0.25 / 150)}
        fill="yellow"
      />
    </>
  );
};

const Drawing = ({ shapes }) => {
  return (
    <>
      {shapes.map((shape, index) => {
        return (
          <Circle
            key={index}
            x={shape.x}
            y={shape.y}
            radius={shape.radius}
            fill={shape.fill}
          />
        );
      })}
    </>);
};

const Field = ({ isAddingShape, onPlaceShape, shapes }) => {
  const [fieldDimensions, setFieldDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [stageDimensions, setStageDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [shapePosition, setCirclePosition] = useState({ x: 0, y: 0 });
  const [isShapeVisible, setIsCircleVisible] = useState(false);

  useEffect(() => {
    const updateStageAndFieldDimensions = () => {
      setStageDimensions({
        width: window.innerWidth * 0.7,
        height: (window.innerWidth * 0.7 * 150) / 65,
      });
      setFieldDimensions({
        height: (window.innerWidth * 0.7 * 150) / 65,
        width: window.innerWidth * 0.7,
      });
    };

    updateStageAndFieldDimensions();
    window.addEventListener("resize", updateStageAndFieldDimensions);

    return () =>
      window.removeEventListener("resize", updateStageAndFieldDimensions);
  }, []);

  const handleMouseMove = (e) => {
    if (isAddingShape) {
      const stage = e.target.getStage();
      const mousePos = stage.getPointerPosition();
      const stagePosition = stage.position();
      setCirclePosition({
        x: mousePos.x - stagePosition.x,
        y: mousePos.y - stagePosition.y,
      });
      setIsCircleVisible(true);
    }
  };

  const handleClick = () => {
    if (isAddingShape) {
      onPlaceShape({ shape: "circle", x: shapePosition.x, y: shapePosition.y, fill: "grey", radius: 10 });
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Box sx={{
        maxHeight: '77vh',
        overflow: 'hidden',
      }} >
        <Stage
          width={stageDimensions.width}
          height={stageDimensions.height}
          style={{ border: "1px solid black" }}
          onMouseMove={handleMouseMove}
          onClick={handleClick}
          x={0}
          y={-fieldDimensions.height * (17 / 150)}
        >
          <Layer scaleX={1} scaleY={1} opacity={0.4}>
            <PermanentFieldMarkings fieldDimensions={fieldDimensions} />


          </Layer>
          <Layer scaleX={1} scaleY={1}>
            <LineOfScrimmage fieldDimensions={fieldDimensions} location={45} />
            <FirstDownLine fieldDimensions={fieldDimensions} location={35} />
            <Drawing shapes={shapes} />
            {isAddingShape && isShapeVisible && (
              <>
                <Circle
                  x={shapePosition.x}
                  y={shapePosition.y}
                  radius={fieldDimensions.width / (65 * 1.5)}
                  fill="grey"
                />
              </>
            )}
          </Layer>
        </Stage>
      </Box>
    </Box >
  );
};
export default Field;