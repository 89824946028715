import { Box, Chip } from "@mui/material";
import AdsClickIcon from "@mui/icons-material/AdsClick";
import PanoramaFishEyeIcon from "@mui/icons-material/PanoramaFishEye";
import NorthIcon from "@mui/icons-material/North";

const onAddShape = (state, setState) => {
  setState({ ...state, isAddingShape: !state.isAddingShape });
};

const EditingMenu = ({ parentState, setParentState }) => {
  const chipStyle = { margin: "2px" };
  return (
    <Box display="flex" flexDirection="column" marginY="10px">
      <Chip sx={chipStyle} icon={<AdsClickIcon />} />
      <Chip
        sx={chipStyle}
        onClick={() => onAddShape(parentState, setParentState)}
        icon={<PanoramaFishEyeIcon />}
      />
      <Chip sx={chipStyle} icon={<NorthIcon />} />
    </Box>
  );
};
export default EditingMenu;