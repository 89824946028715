import React from 'react';
import { Typography } from "@mui/material";


export default function OrganizationMembers() {
    return (
        <>
            <Typography variant="h3">Members</Typography>
        </>
    );
}