import React, { useState } from "react";
import { Typography, Box, Button, CircularProgress } from "@mui/material";
import { get } from "aws-amplify/api";
import { fetchAuthSession } from "aws-amplify/auth";

const Organizations = () => {
    const [organizations, setOrganizations] = useState([]);
    const [formState, setFormState] = useState({
        isFetching: false,
        isFetched: false,
    });

    const fetchOrganizations = async () => {
        setFormState({ ...formState, isFetching: true });
        const restOperation = get({
            apiName: "user_management",
            path: "/tenants/list",
            options: {
                headers: {
                    "Authorization": (await fetchAuthSession()).tokens?.idToken?.toString()
                }
            }
        });
        const { body } = await restOperation.response;
        const json = await body.json();
        setOrganizations(json);
        setFormState({ ...formState, isFetching: false, isFetched: true });
    };

    if (!formState.isFetching && !formState.isFetched) {
        fetchOrganizations();
    }



    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" paddingTop="30px">
                <Box display="flex" justifyContent="flex-end" width="100%" marginRight="3vh">
                    <Button
                        variant="contained"
                        color="primary"
                        href="/admin/create-organization"
                    >
                        <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '18px', color: '#003459', fontWeight: "bold" }}>
                            Create Organization
                        </Typography>
                    </Button>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" marginTop="4vh">

                </Box>
                {formState.isFetching && <CircularProgress />}
                {formState.isFetched && organizations.length > 0 && organizations.map((organization, index) => (
                    <Box display="flex" flexDirection="row" alignItems="left" justifyContent="left" key={index}>
                        <Typography component="a" href={`/organization/${organization.id}`}>{organization.name}</Typography>
                    </Box>
                ))}
            </Box>
        </Box>
    );
};

export default Organizations;