import { Button, TextField, Typography, Box } from "@mui/material";
import React, { useState } from "react";
import Field from "../../components/Field";
import EditingMenu from "./EditingMenu";
import { post } from 'aws-amplify/api';
import { fetchAuthSession } from "aws-amplify/auth";
import FileExplorer from "./FileExplorer";

const onClickEdit = async (state, setState) => {
  const isSaving = state.editing;
  setState({ ...state, editing: !state.editing });

  if (isSaving) {
    post({
      apiName: "playbook",
      path: "/create",
      options: {
        body: {
          name: `${state.formation} - ${state.play} vs ${state.vsFormation} - ${state.vsCoverage}`,
          shapes: state.shapes,
        },
        headers: {
          "Authorization": (await fetchAuthSession()).tokens?.idToken?.toString()
        }
      }
    });
  }
};

const onNameChange = (e, state, setState) => {
  setState({ ...state, name: e.target.value });
};



const PlayDesigner = () => {
  const [state, setState] = useState({
    editing: false,
    team: "Offense",
    los: 45,
    down: 1,
    toGo: 10,
    formation: "Pistol",
    play: "Inside Zone",
    vsFormation: "4-3",
    vsCoverage: "Cover 3",
    hash: "Right",
    shapes: [],
    isAddingShape: false,
  });


  const onPlaceShape = (shape) => {
    setState({
      ...state,
      isAddingShape: !state.isAddingShape,
      shapes: [...state.shapes, shape],
    });
  };

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center" paddingTop="30px">
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" width="70%">
          <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center">
            <Button
              variant="text"
              color="primary"
              onClick={() => onClickEdit(state, setState)}
            >
              <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '28px', fontWeight: "bold" }}>
                {state.editing ? "Save" : "Edit"}
              </Typography>
            </Button>
          </Box>
          {state.editing ? (
            <>
              <TextField
                disabled={!state.editing}
                onChange={(e) => onNameChange(e, state, setState)}
                defaultValue={state.formation}
                sx={{ padding: '10px' }}
                multiline
                inputProps={{ style: { flexGrow: 1, textAlign: 'center', fontSize: '32px', color: 'grey', fontWeight: "bold", resize: "horizontal" } }}
              />
              <TextField
                disabled={!state.editing}
                onChange={(e) => onNameChange(e, state, setState)}
                defaultValue={state.play}
                sx={{ padding: '10px' }}
                multiline
                inputProps={{ style: { flexGrow: 1, textAlign: 'center', fontSize: '32px', color: 'grey', fontWeight: "bold", resize: "both" } }}
              />
              <Typography style={{
                flexGrow: 1,
                textAlign: 'center',
                verticalAlign: 'middle',
                fontSize: '32px',
                color: 'grey',
                fontWeight: "bold"
              }}>
                vs
              </Typography>
              <TextField
                disabled={!state.editing}
                onChange={(e) => onNameChange(e, state, setState)}
                defaultValue={state.vsFormation}
                sx={{ padding: '10px' }}
                multiline
                inputProps={{ style: { flexGrow: 1, textAlign: 'center', fontSize: '32px', color: 'grey', fontWeight: "bold", resize: "both" } }}
              />
              <TextField
                disabled={!state.editing}
                onChange={(e) => onNameChange(e, state, setState)}
                defaultValue={state.vsCoverage}
                sx={{ padding: '10px' }}
                multiline
                inputProps={{ style: { flexGrow: 1, textAlign: 'center', fontSize: '32px', color: 'grey', fontWeight: "bold", resize: "both" } }}
              />
            </>
          ) : (
            <>
              <Typography style={{ flexGrow: 1, textAlign: 'center', fontSize: '32px', color: 'grey', fontWeight: "bold" }}>
                {state.formation} - {state.play}  vs  {state.vsFormation} - {state.vsCoverage}
              </Typography>
            </>
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="row" justifyContent="center" alignItems="flex-start" paddingTop="20px">
        <FileExplorer />
        <Field isAddingShape={state.isAddingShape} onPlaceShape={onPlaceShape} shapes={state.shapes} />
        {state.editing && (
          <Box marginX="20px">
            <EditingMenu
              editing={state.editing}
              parentState={state}
              setParentState={setState}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default PlayDesigner;